import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Logo = ({ className, width }) => {
  return (
    <img src={'/assets/images/white-logo.svg'} width={width || 210} alt={'Logo'} className={className} />

  );
};

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
